 
import { computed, inject, Injectable, signal } from '@angular/core';
import { Auth, onAuthStateChanged, sendPasswordResetEmail, signInWithEmailAndPassword, signOut, updateProfile, user } from '@angular/fire/auth';
import { Database,  ref,  update } from '@angular/fire/database';
import { createUserWithEmailAndPassword } from 'firebase/auth'; 
import { DataSnapshot, onValue, query } from 'firebase/database';
import { MessageService } from 'primeng/api';
import { Observable, from } from 'rxjs';

 

@Injectable({
  providedIn: 'root'
})
export class FirebaseAuthService {

  currentUserSignal = signal<any | null | undefined>(undefined);
  crmCurrentUser = signal<any | null | undefined>(undefined);

  currentUserToken = computed(() => this.currentUserSignal()?.accessToken );

  fbUser$ = user(this.auth);

  messageService = inject(MessageService);

  constructor(private auth: Auth, private db: Database) { 
    this.auth.languageCode = 'it';
  }  

  resetEmail(email: string): Observable<void>  {
    const promise = sendPasswordResetEmail(this.auth, email)
    .then(() => {
       console.log('>>>> ', email, ' email sent to reset password');
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message; 
      console.error('>>>> ERROR ', errorCode, errorMessage);
    });
    return from(promise);
  }


  // trasformo la Promise in un Observable per poterlo utilizzare in un componente
  register(username: string, email: string,  password: string): Observable<void> {
      const promise = createUserWithEmailAndPassword(this.auth, email, password)
        .then((userCredential) => {
          updateProfile(userCredential.user, { displayName: username }).then(() => {
            this.login(email, password);
          });
        },
        (error) => {
          const errorCode = error.code;
          const errorMessage = error.message; 
          console.error('>>>> ERROR ', errorCode, errorMessage);

          this.messageService.add({
            severity: 'error',
            summary: 'Errore',
            detail: `Errore ${error.code}: ${error.message}`,
            life: 10000 // Il toast rimarrà visibile per 5 secondi
          });  
        }
      );

      return from(promise);
  }

    

  // Metodo per il login
  login(email: string, password: string): Observable<void>  {
      const promise =  signInWithEmailAndPassword(this.auth, email, password)
          .then((userCredential) => {  
            // console.log( '>>> LOGIN OK :: user', userCredential.user ) ;  
          } 
        );

      return from(promise);
  }

 

  setCRMuserStatus(user: any, status: string ) {  
    if(!user) return;

    const statusPath = `crm-on-users/${user.uid}`;
    const doc = ref(this.db,  statusPath); 
    const record = {
      // 'supreadmin' | 'admin' | 'superuser' | 'user' | 'guest' | 'banned' | 'deleted'   
      userId: `${user.uid}`,
      name: `${user.name || user.displayName?.split(' ')[0]}` ,
      surname: `${user.surname || user.displayName?.split(' ')[1]}`,
      displayName: `${user.displayName}`,
      email: `${user.email}`,
      status: `${status}`,
      createdAt: `${user.metadata.creationTime}`, 
      date: `${user.metadata.lastSignInTime}`,
      photoURL: `${user.photoURL}`,
      phoneNumber: `${user.phoneNumber}`
    };   
    
    update(  doc, record )
      .then(() => { 
        //console.log('>>>>> UPDATED USER ', user, status);    
      })
      .catch((error) => {
         console.error('>>>>> setUserStatus  error', error);
      });   
  }  

  // Metodo per il logout
  logout(): Observable<void>   { 
    const promise = signOut(this.auth); 
    this.crmCurrentUser.set(null); 
    return from(promise);
  }


  getCRMuser(user: any)  { 
    const collection  = query(ref(this.db, `crm-on-users/${user.uid}`));
      onValue(collection, (snapshot: DataSnapshot) => {      
        const crmUser =  snapshot.val();   
        if (crmUser) {
          //console.log('>>>> CRM USER EXISTED ', crmUser);
          this.crmCurrentUser.set(crmUser);  
          this.currentUserSignal.set({...user, role: crmUser.role, crmUser: crmUser});        
        } else {
          //console.log('>>>> NEW USER ' ); 
          this.currentUserSignal.set({...user, role: 'guest'});
        }
      }, (errorObject: any) => {
        console.error('>>>> retrieve fb translations ' , errorObject.name);
      }); 
  }

  isUserLoggedIn() {
    return onAuthStateChanged(this.auth, user => {
      if (user) {
        //console.log('>>> User is logged in', user); 
        this.setCRMuserStatus(user,  'online');
        this.getCRMuser(user);  
      } else { 
        //console.log('User  is logged out');
        this.setCRMuserStatus(this.currentUserSignal(),  'offline');
        this.currentUserSignal.set(null);
        this.crmCurrentUser.set(null);
      }
    });
  }

}
