const serverBE = 'https://dev.crm.api.oncharge.it/api/v1';  //? BE DEV 

export const environment = { 

  environment: 'DEV',

  production: false,  
  dev: true,        //! DEV ENV
  uat: false,    

  baseServerURL: `${serverBE}/`,     
 
  firebase: {
    apiKey: "AIzaSyDHaS_SoRhf9nX3GZIn4eiCx1LWJ-e0WIg",
    databaseURL: "https://zeus-7e655-default-rtdb.europe-west1.firebasedatabase.app",
    authDomain: "zeus-7e655.firebaseapp.com",
    projectId: "zeus-7e655",
    storageBucket: "zeus-7e655.appspot.com",
    messagingSenderId: "74069116625",
    appId: "1:74069116625:web:3399dd2f51da7ab10167f0",
    measurementId: "G-WBLKT9E1C4"
  },

  googleMapsApiKey: 'AIzaSyCjkQhKh9IrfCYNnve7vj_XlPGGT9LMa20' ,

  // sentry: {
  //   dsn: 'https://138dab8cb7294cd3e6163b12032a4c9f@o4506734805385216.ingest.sentry.io/4506734808399872'
  // }
};

