import { computed, CreateEffectOptions, effect, inject, Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs'; 
import { FirebaseAuthService } from '../features/views/auth/services/firebase-auth.service';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {

  userRole = computed(() => this.fbAuthService.crmCurrentUser()?.role || '' ); 
  attempts = 0;

  constructor(
    private messageService: MessageService,
    private fbAuthService: FirebaseAuthService, 
    private router: Router) {      }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree   {  

    const requiredRoles: string[] = route.data['roles'] ;
    return this.hasRoles(requiredRoles ).then((hasGrants: boolean) => {

      if (hasGrants) {
        return true;
      } else { 
          this.messageService.clear(); // close others
          this.messageService.add({
              severity: 'error',
              summary: 'Errore',
              detail: `Non hai i permessi per accedere a questa pagina`,
              life: 6000 // Il toast rimarrà visibile per 6 secondi
          });  
          return this.router.createUrlTree(['/']);
      } 
    });
  } 
  
  hasRoles(requiredRoles: string[]): Promise<boolean> { 
  
    return new Promise((resolve) => {

      const checkRole = () => {
        const hasPermission = requiredRoles.includes(this.userRole()); 
  
        if (hasPermission) {
          resolve(true);
        } else if (!hasPermission && this.attempts < 2) {
          this.attempts += 1;
          setTimeout(() => {
            checkRole();
          }, 1000);  
        } else {
          resolve(false);
        }
      };
  
      checkRole();
    });
  } 



}