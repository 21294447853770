import { Component, OnInit } from '@angular/core';
// import { ButtonModule } from 'primeng/button';  
// import { PanelMenuModule } from 'primeng/panelmenu';
// import { CarouselModule } from 'primeng/carousel';
// import { InputTextModule } from 'primeng/inputtext';
// import { RouterModule } from '@angular/router'; 
 

@Component({
  selector: 'app-landing',
  standalone: true,
  imports: [
    // RouterModule,
    // ButtonModule,
    // ButtonModule,        
    // PanelMenuModule,    
    // CarouselModule,     
    // InputTextModule 
  ],
  templateUrl: './landing.component.html',
  styleUrl: './landing.component.scss'
})
export class LandingComponent implements OnInit {  

  constructor( ) { }

  ngOnInit(): void {   }

}
