 

  

 <!-- <pre>{{ ( fbAuthService.fbUser$ | async ) | json }}</pre> -->



  <!-- <p-button label="Check" icon="pi pi-check"></p-button>

  <p-button label="Danger" icon="pi pi-times" styleClass="p-button-danger"></p-button>
  <p-button label="Success" icon="pi pi-check" styleClass="p-button-success"></p-button>
  <p-button label="Warning" icon="pi pi-check" styleClass="p-button-warning"></p-button>
  <p-button label="Info" icon="pi pi-check" styleClass="p-button-info"></p-button><br><br>

  <p-button label="Text Btn" icon="pi pi-check" styleClass="p-button-text"></p-button>

  <p-button label="outlined" icon="pi pi-check" styleClass="p-button-outlined"></p-button>
  <p-button label="rounded" icon="pi pi-check" styleClass="p-button-rounded"></p-button>
  <p-button label="raised" icon="pi pi-check" styleClass="p-button-raised"></p-button><br><br> 
  <p-button label="outlined raised" icon="pi pi-check" styleClass="p-button-outlined p-button-raised"></p-button>
  <p-button label="Save" icon="pi pi-check" styleClass="p-button-text p-button-rounded"></p-button>
  <p-button label="Save" icon="pi pi-check" styleClass="p-button-text p-button-raised"></p-button><br><br>
  <p-button label="Save" icon="pi pi-check" styleClass="p-button-text p-button-outlined"></p-button>
  <p-button label="Save" icon="pi pi-check" styleClass="p-button-text p-button-outlined p-button-rounded"></p-button>
  <p-button label="Save" icon="pi pi-check" styleClass="p-button-text p-button-outlined p-button-raised"></p-button>
  <p-button label="Save" icon="pi pi-check" styleClass="p-button-text p-button-raised p-button-rounded"></p-button><br><br>
  <p-button label="Save" icon="pi pi-check" styleClass="p-button-text p-button-raised p-button-outlined"></p-button>
  <p-button label="Save" icon="pi pi-check" styleClass="p-button-text p-button-raised p-button-outlined p-button-rounded"></p-button> <br><br>
  <p-divider />
  <i class="pi pi-check"></i> &nbsp;
  <i class="pi pi-times"></i> &nbsp;
  <span class="pi pi-search"></span> &nbsp;
  <span class="pi pi-user"></span>  &nbsp;
  <span class="pi pi-bell"></span>  &nbsp;
  <span class="pi pi-calendar"></span>  &nbsp;
  <span class="pi pi-times-circle"></span>  &nbsp;
  <span class="pi pi-sign-in"></span>  &nbsp;
  <span class="pi pi-sign-out"></span>  &nbsp;
  <span class="pi pi-bars"></span>  &nbsp;
  <span class="pi pi-home"></span>  &nbsp;  
  <span class="pi pi-chevron-right"></span>  &nbsp;
  <span class="pi pi-chevron-left"></span>  &nbsp;
  <span class="pi pi-chevron-up"></span>  &nbsp;
  <span class="pi pi-chevron-down"></span>  &nbsp; 
  <p-divider />  -->
 
 
 


<div class="login-container">

  <div class="card flex justify-content-center">
    <p-card header="" subheader="" [style]="{ width: '560px' }">
        <ng-template pTemplate="header"  >
            <span>Login</span>
            <img alt="OnCharge logo" src="./../../../../assets/img/LogoONe-trans.png" style="width:100px; text-align: right" />
        </ng-template>
        
      

          <form class="login-form"   [formGroup]="login"  autocomplete="off">

            <firebase-ui  
                (signInSuccessWithAuthResult)="successCallback($event)" 
                (signInFailure)="errorCallback($event)"
                (uiShown)="uiShownCallback()" >
            </firebase-ui>  

            <p-divider /> 

           

            <p-iconField iconPosition="right" *ngIf="register">
              <p-floatLabel>
                  <p-inputIcon styleClass="pi pi-user" />
                  <input 
                    pInputText id="userName" 
                    autocomplete="off"   
                    [formControlName]="'username'" 
                    required 
                    autocomplete="off" />
                  <label for="newterm"> {{ translationServ.translationsSIG()?.['placeholder-username'] ?? 'placeholder-username' }} </label>
              </p-floatLabel>
            </p-iconField>

            @if (login.get('username')?.hasError('required')) {
              <span class="error-message">{{ translationServ.translationsSIG()?.['error-username-required'] ?? 'error-username-required' }}</span>
            } 


            <p-iconField iconPosition="right" >
              <p-floatLabel>
                  <p-inputIcon styleClass="pi pi-at" />
                  <input 
                    pInputText 
                    id="email"
                    type="email" 
                    #email
                    autocomplete="off"
                    [formControlName]="'email'"  
                    required
                    autocomplete="off" />
                  <label for="newterm"> {{ translationServ.translationsSIG()?.['placeholder-email'] ?? 'placeholder-email' }} </label>
              </p-floatLabel>
            </p-iconField> 

            @if (login.get('email')?.hasError('required')) {
              <span class="error-message">{{ translationServ.translationsSIG()?.['error-email-required'] ?? 'error-email-required' }}</span>
            } 
            @if (login.get('email')?.hasError('email')) {
              <span class="error-message">{{ translationServ.translationsSIG()?.['error-invalid-email'] ?? 'error-invalid-email' }}</span>
            }



            <!-- TODO : IMPLEMENT THIs for PW CREATION  -->
            <!-- <p-password [(ngModel)]="value">
              <ng-template pTemplate="header">
                  <h6>Pick a password</h6>
              </ng-template>
              <ng-template pTemplate="footer">
                  <p-divider />
                  <p class="mt-2">Suggestions</p>
                  <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                      <li>At least one lowercase</li>
                      <li>At least one uppercase</li>
                      <li>At least one numeric</li>
                      <li>Minimum 8 characters</li>
                  </ul>
              </ng-template>
          </p-password> -->

            <p-iconField iconPosition="right" >
              <p-floatLabel>
                  <p-inputIcon styleClass="pi pi-key" />
                  <input 
                    pInputText 
                    id="password"
                    type="password" 
                    #password
                    autocomplete="off"
                    [formControlName]="'password'"   
                    required
                    autocomplete="off" />
                  <label for="newterm"> {{ translationServ.translationsSIG()?.['placeholder-password'] ?? 'placeholder-password' }} </label>
              </p-floatLabel>
            </p-iconField> 

            @if (login.get('password')?.hasError('required')) {
              <span class="error-message">{{ translationServ.translationsSIG()?.['error-password-required'] ?? 'error-password-required' }}</span>
            }
            @if (login.get('password')?.hasError('minlength')) {
              <span class="error-message">{{ translationServ.translationsSIG()?.['error-mineightdigits-required'] ?? 'error-mineightdigits-required' }}</span> 
            } 
          </form>



       
        <ng-template pTemplate="footer">
            <div class="flex justify-between gap-3 mt-1 relative">
                <i class="pi pi-spin pi-spinner spinner" *ngIf="showSpinner()" ></i>
                <span  class="w-full flex gap-3 justify-end"> 
                  <p-button label="{{ translationServ.translationsSIG()?.['button-forgot-pw'] ?? 'button-forgot-pw' }}"  (click)="forgotPassword(email.value)"  [disabled]="login.get('email')?.hasError('required')"  class="w-auto first-letter-capitalize" styleClass="p-button-text" />
                  <p-button label="{{ translationServ.translationsSIG()?.['button-register'] ?? 'button-register' }}" *ngIf="!register" (click)="showRegister()"  [disabled]="login.valid"   class="w-auto first-letter-capitalize" styleClass="p-button-text p-button-outlined p-button-rounded" />

                  <p-button label="{{ translationServ.translationsSIG()?.['button-login'] ?? 'button-login' }}" *ngIf="!register"   (click)="loginOnFirebase()"  [disabled]="!login.valid"  class="w-auto" styleClass="p-button-rounded" />
                  <p-button label="{{ translationServ.translationsSIG()?.['button-register'] ?? 'button-register' }} & {{ translationServ.translationsSIG()?.['button-login'] ?? 'button-login' }}" *ngIf="register"  (click)="registerOnFirebase()"  [disabled]="!login.valid"  class="w-auto" styleClass=" p-button-rounded" />
                </span>
            </div>
        </ng-template>
    </p-card>
</div> 
   
    <!-- <mat-card class="border-primary" >
      <mat-card-header>
        <mat-card-title *ngIf="!register" >Accedi</mat-card-title> 
        <mat-card-title *ngIf="register" >Registrati</mat-card-title> 
      </mat-card-header>
 
      <mat-card-content>
        <form class="login-form"   [formGroup]="login"  >

          <firebase-ui  
              (signInSuccessWithAuthResult)="successCallback($event)" 
              (signInFailure)="errorCallback($event)"
              (uiShown)="uiShownCallback()" >
          </firebase-ui>  
   
          <p-divider /> 

          <mat-form-field appearance="outline"   *ngIf="register">
            <mat-label>User Name</mat-label>
            <input matInput color="accent" 
                placeholder="Inserisci il tuo nome utente" 
                type="text"  
                autocomplete="off"   
                [formControlName]="'username'" 
                required >
                @if (login.get('username')?.hasError('required')) {
                  <mat-error>Username required</mat-error>
                } 
          </mat-form-field>


          <mat-form-field appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput color="accent" 
                type="email" 
                #email
                autocomplete="off"
                [formControlName]="'email'" 
                placeholder="Inserisci la tua email"  
                required="true">
                @if (login.get('email')?.hasError('required')) {
                  <mat-error>Email required</mat-error>
                } 
                @if (login.get('email')?.hasError('email')) {
                  <mat-error>Invalid email format</mat-error>
                }
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Password</mat-label>
            <input matInput color="accent" 
                placeholder="Inserisci la tua password" 
                type="password"  
                autocomplete="off"   
                [formControlName]="'password'" 
                required >
                @if (login.get('password')?.hasError('required')) {
                  <mat-error>Password required</mat-error>
                }
                @if (login.get('password')?.hasError('minlength')) {
                    <mat-error>Minimum 8 characters required</mat-error> 
                }
          </mat-form-field>
          <button mat-flat-button  *ngIf="!register"
            (click)="loginOnFirebase()" 
            [disabled]="!login.valid" 
            color="accent" type="button">Login</button>

          <button mat-flat-button *ngIf="register"
            (click)="registerOnFirebase()" 
            [disabled]="!login.valid" 
            color="accent" type="button">Login</button>
        </form>
      </mat-card-content>  
      
      <mat-card-actions>        
        <button mat-button *ngIf="!register" (click)="showRegister()">Registrati</button>
        <button mat-button *ngIf="register" (click)="showLogin()">Accedi</button>
        <button mat-button (click)="forgotPassword(email.value)" [disabled]="login.get('email')?.hasError('required')">Ho dimenticato la password</button>
      </mat-card-actions>
    </mat-card> -->
  </div>


  <p-toast /> 
  

