import { computed, Injectable, signal } from '@angular/core';
import {  DataSnapshot, endAt, endBefore,  get,  limitToLast, onValue, orderByChild, orderByKey, Query, query, ref, remove, set,  startAt, update } from 'firebase/database';
import {  Database } from '@angular/fire/database';   
import { CookieService } from '../../../../services/cookie.service'; 
import { BROWSER_LANG_SHORT } from '../../../../app.config';
import { ComboLanguageInterface } from '../../../../interfaces/combo-language';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  currentPage = signal<number>(0);
  currentRPP = signal<number>(0);
  currentFilterValue  = signal<string>('');
  currentFilterKey  = signal<string>('');

  filteredDataSIG = signal<any[]>([]);
  translationsSIG = signal<any>({});
  private termsSIG = signal<any[] | [] >([]); 
  readonly pubTermsSig = computed( () => this.termsSIG() );
  private allTermsSIG = signal<any[] | [] >([]);
  readonly pubAllTermsSig = computed( () => this.allTermsSIG() );
  totTerms = signal<number>(0);  

  platformSIG = signal<string>('crm-on-translations'); 
  currentLangSIG = signal<string>('');
  currentLangIcoSIG = signal<string>('');

  private languages = signal<ComboLanguageInterface[]>([]); 
  readonly languagesArray = computed<ComboLanguageInterface[]>(() => [...this.languages()]); // getter of languages array
  readonly selectedLang = computed<ComboLanguageInterface | null>( ()=> { 
    const selectedLangOpt = this.languagesArray().find( (lng) => lng.value ===  this.currentLangSIG() );  
    return (selectedLangOpt) ? {...selectedLangOpt} : null;  
  });  

  constructor(   
    private database: Database ,  
    private messageService: MessageService,
    private cookieService: CookieService ) { 
        const favLang = this.checkMyFavouriteLangauge();
        this.currentLangSIG.set(favLang);
    }
    
    //? ORCHESTRATOR
    retrieveRecords(rpp: number, page: number, searchValue: string, searchKey: string) {         
        this.currentPage.set(page);
        this.currentRPP.set(rpp);

        if(searchValue === '' || searchValue === null) {
          this.currentFilterValue.set(''); 
          this.currentFilterKey.set('');
          this.getTotTerms(); 
          this.getPaginatedDocs(); 
        } else {
          this.currentFilterValue.set(searchValue); 
          this.currentFilterKey.set(searchKey);
          this.filterAndPaginate(); 
        }
    } 
    
      //? #########   PAGINATED ######### 
    async getPaginatedDocs(): Promise<any[]> {
        const page = this.currentPage();
        const rpp = this.currentRPP();

        const ln = this.currentLangSIG();
        if (ln === '') return [];
    
        const collectionRef = ref(this.database, `${this.platformSIG()}/translations/${ln}`);
        let queryRef: Query;
    
        if (page === 0) {
            // Prima pagina: prendo gli ultimi record per ottenere l'ordine decrescente PER CHIAVE
            queryRef = query(collectionRef, orderByKey(), limitToLast(rpp));
        } else {
            // Calcolo l'elemento di inizio per la pagina corrente
            const lastItemKey = await this.getLastKeyOfPreviousPage(page, rpp);
            if (lastItemKey) {
                queryRef = query(collectionRef, orderByKey(), endBefore(lastItemKey), limitToLast(rpp));
            } else {
                return []; // Se non c'è un lastItemKey, significa che non ci sono più dati
            }
        }
    
        return get(queryRef).then(snapshot => {
            const jsonRes = snapshot.val();
            if (jsonRes) {
                const dataArr = Object.entries(jsonRes).map(([selector, translation]) => {
                    return { selector, translation };
                });
    
                // Ribaltare i risultati per ottenere l'ordine discendente
                dataArr.reverse();
    
                this.termsSIG.set([...dataArr]);
                return dataArr; // Restituisci i dati paginati
            }
            return [];
        });
    }
  
    private async getLastKeyOfPreviousPage(page: number, rpp: number): Promise<string | null> {
        const ln = this.currentLangSIG();
        if (ln === '') return null;
    
        const collectionRef = ref(this.database, `${this.platformSIG()}/translations/${ln}`);
        const previousPageQuery = query(collectionRef, orderByKey(), limitToLast((page) * rpp));
    
        const snapshot = await get(previousPageQuery);
        const jsonRes = snapshot.val();
        if (jsonRes) {
            const keys = Object.keys(jsonRes);
            return keys[0] || null; // Restituisco la chiave dell'ultimo elemento della pagina corrente
        }
        return null;
    }
  
    //? FILTERED & PAGINATED  
    filterAndPaginate() { 
      const page = this.currentPage();
      const rpp = this.currentRPP();
      const partialSearchValue = this.currentFilterValue();
      const searchKey = this.currentFilterKey(); 

      if(this.currentFilterValue() === '') return;  
      if(this.currentLangSIG() === '') return;   

      let collection: Query = ref(this.database, `${this.platformSIG()}/translations/${this.currentLangSIG()}`); 

      if(searchKey === 'translation') {
        collection = query(
          ref(this.database, `${this.platformSIG()}/translations/${this.currentLangSIG()}`),
          orderByChild(searchKey)
        ); 
      } else if(searchKey === 'selector') {
        collection = query( 
          ref(this.database, `${ this.platformSIG() }/translations/${this.currentLangSIG()}`),
          orderByKey(),
          startAt(partialSearchValue),
          endAt(partialSearchValue + '\uf8ff') 
        ); 
      }     
    
      onValue(collection, (snapshot: DataSnapshot) => {
        const jsonRes = snapshot.val(); 
        
        if (!jsonRes) return; 
    
        let dataArr = Object.entries(jsonRes).map(([selector, translation]) => {
          return { selector, translation };
        });  

        if (searchKey === 'translation') {
          dataArr = dataArr.filter(item => (item.translation as string).includes(partialSearchValue));
        }
           
        const start = (page ) * rpp;  
        const end = ((page) * rpp) + rpp;  
        this.termsSIG.set( [...dataArr.slice(start, end-1)] );    

        this.totTerms.set(dataArr.length); // aggiorna il contatore signal

    
      }, (errorObject: any) => {
        console.error('>>>> retrieve tot filtered fb terms', errorObject.name);
      });
    }
 


    //? count all terms
    getTotTerms(){
      const collection  = query(  ref(this.database, `${ this.platformSIG() }/totTerms`) );
      onValue(collection, (snapshot: DataSnapshot) => {      
        const tot =  snapshot.val();     
        this.totTerms.set(tot); 
      }, (errorObject: any) => {
        console.error('>>>> retrieve fb terms ' , errorObject.name);
      }); 
    }
 

    updateTotTerms(tot: number){
      const collection  =   ref(this.database, `${ this.platformSIG() }/totTerms`)  ;   
      set(  collection, tot )
        .then(() => {
          
        })
        .catch((error) => {
          console.error('>>>>> updateTotTerms Firebase: error', error);
        });  
    }

    //? for translation all around site
    retrieveAllTerms(){     
      const ln = this.currentLangSIG();
      if(ln === '') return;      
      const collection  = query(ref(this.database, `${ this.platformSIG() }/translations/${ln}`),  orderByKey() );
    
      onValue(collection, (snapshot: DataSnapshot) => {    

        const jsonRes =  snapshot.val();   

        if(!jsonRes) return;

        this.translationsSIG.set( { ...jsonRes });
      
        const dataArr = Object.entries(jsonRes).map(([selector, translation]) => {          
          return { selector, translation };
        }); 

        const currentFlag  = dataArr.find((item: any) => item.selector === 'app-language-flag')?.translation  as string;  
        this.currentLangIcoSIG.set(currentFlag);
  
        this.allTermsSIG.set( [...dataArr]);
        this.updateTotTerms(dataArr.length); // setta il valore in tabella
        this.totTerms.set(dataArr.length);   // aggiorna il contatore signal

      }, (errorObject: any) => {
        console.error('>>>> retrieve fb TRANSLATIONS ' , errorObject.name);
      }); 
    }

    //? setter  of languages array 
    setLanguages(newLang: ComboLanguageInterface[]) { 
      this.languages.update( langs => [...langs, ...newLang]);  
    }

    //? for the select of languages
    getFirebaselangs() {         
      const collection  = query(ref(this.database, `${this.platformSIG()}/languages`));
      onValue(collection, (snapshot: DataSnapshot) => {      
        const jsonRes =  snapshot.val();   
        this.setLanguages(jsonRes);
      }, (errorObject: any) => {
        console.error('>>>> retrieve fb translations ' , errorObject.name);
      }); 
    }


    //? ad a new selector and the same definition for all languages
    addNewTerm(selector: string, definition: string) { 
      selector = selector.toLowerCase(); 
      selector = selector.replace(/\./g, '-');  
      
      const counter = this.totTerms() + 1; // nuovo contatore

      for( const lan of this.languages()) {  
        const nodeLang = ref(this.database, `${ this.platformSIG() }/translations/${lan.value}`);
        const data = { [String(selector)]: definition };
  
        update(nodeLang, data).then(() => { 
          this.updateTotTerms(counter); // aggiorno il contatore
        }).catch((error) => {
          console.error('>>>>> addNewTerm Firebase: error', error); 
        });   
      }  

      const message =  ( this.translationsSIG()?.['toast-term-succesful-added'] ??  'toast-term-succesful-added' ) + ' !' ;
      this.messageService.add({ severity: 'success', summary: 'OK', detail: message }); 

      this.retrieveRecords(this.currentRPP(), this.currentPage(), this.currentFilterValue(), this.currentFilterKey()); // aggiorna la tabella paginata e o filtrata
     
    }
    

    //? translate a single definition
    updateTranslation(definition: string, selector: string) {   
      selector = selector.toLowerCase();

      const ln = this.checkMyFavouriteLangauge();   
      const doc = ref(this.database,  `${this.platformSIG()}/translations/${ln}/${selector}`);
      set(  doc, definition )
          .then(() => {
            // console.log('>>>>> updateTranslation Firebase item: OK'); 
          })
          .catch((error) => {
            console.error('>>>>> updateTranslation Firebase: error', error);
          }); 
    }

    //?  delete the same selector for all languages
    deleteTerm(selector: string) {   
      const isOk = confirm(this.translationsSIG()['confirm-to-delete-term'] + ' >>> ' + selector ); 

      if (isOk) { 
        const counter = this.totTerms() - 1; // nuovo contatore 
        for( const ln of this.languages()) {  
          const doc = ref(this.database,  `${ this.platformSIG() }/translations/${ln.value}/${selector}`);
          remove(doc).then(() => { 
              // console.log('>>>>> delete Firebase item: success'); 
              this.updateTotTerms(counter); // aggiorno il contatore
          }).catch((error) => {
              console.error('>>>>> delete Firebase: error', error); 
          }); 
        } 

        this.retrieveRecords(this.currentRPP(), this.currentPage(), this.currentFilterValue(), this.currentFilterKey()); // aggiorna la tabella paginata e o filtrata
      }
    }


    //TODO : soon
    //   addNewLanguage(lng: string, label: string) {  
    //     const data: ComboLanguageInterface = {  
    //       'ico': `https://purecatamphetamine.github.io/country-flag-icons/3x2/${lng.toUpperCase()}.svg`, 
    //       'label': label,
    //       'value': lng
    //     }; 
    //     const languagesRef =  ref(this.database, `${this.basePath}/languages`); 
    //     // Ottieni l'elenco corrente dei linguaggi, aggiungi il nuovo linguaggio e poi aggiorna il nodo del database.
    //     get(child(languagesRef, '/')).then((snapshot) => {
    //       if (snapshot.exists()) {
    //         const currentLanguages = snapshot.val();
    //         currentLanguages.push(data);
    //         set(languagesRef, currentLanguages);
    //       } else {
    //         console.log("No data available");
    //       }
    //     }).catch((error) => {
    //       console.error(error);
    //     }); 
    //     // creo un set di selettori copiando l'ultimo set in inglese
    //     const collection  = query(ref(this.database, `${this.basePath}/translations/en`));
    //     let data2: any = null;
    //     onValue(collection, (snapshot: DataSnapshot) => {      
    //       data2 =  snapshot.val();   
    //       data2['app-language-flag'] = `https://purecatamphetamine.github.io/country-flag-icons/3x2/${lng.toUpperCase()}.svg`;
    //       const newTranslationsNode = ref(this.database, `${this.basePath}/translations/${lng}`); 
    //       update(newTranslationsNode, data2);
    //     }, (errorObject: any) => {
    //       console.error('>>>> retrieve fb translations ' , errorObject.name);
    //     });   
    // }

    //? cookie favourite language
    checkMyFavouriteLangauge(): string {
      if (this.cookieService.check('chosen-lang')) {
        this.currentLangSIG.set(this.cookieService.get('chosen-lang')); 
        return this.cookieService.get('chosen-lang');
      }; 
      this.setCookieFavouriteLNG(BROWSER_LANG_SHORT, 'checkMyFavouriteLangauge if non cookie found');
      return BROWSER_LANG_SHORT;
    } 
    setCookieFavouriteLNG(lang: string, who: string) {    
      this.currentLangSIG.set(lang);    
      this.cookieService.delete('chosen-lang');  
      this.cookieService.set('chosen-lang', lang, 365, '/');   
    }
    getCookieFavouriteLNG(): string {   
      return this.cookieService.get('chosen-lang');
    } 

}
