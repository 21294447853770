import { CommonModule, JsonPipe } from '@angular/common';
import { Component, CreateEffectOptions, effect, EffectRef, OnInit, signal } from '@angular/core';   
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Subject, takeUntil } from 'rxjs';    
import { ButtonModule } from 'primeng/button'; 
import { DividerModule } from 'primeng/divider';    
import { Router } from '@angular/router'; 

import { FirebaseUIModule, FirebaseUISignInFailure, FirebaseUISignInSuccessWithAuthResult, 
  // FirebaseuiAngularLibraryService, firebase, firebaseui 
} from 'firebaseui-angular'; 
import { CardModule } from 'primeng/card';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { TranslationService } from '../translations/services/translations.service';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { FirebaseAuthService } from './services/firebase-auth.service';
  

@Component({
  selector: 'app-auth',
  standalone: true,
  imports: [ 
    CommonModule, 
    ToastModule,
    FirebaseUIModule, 
    JsonPipe,
    ReactiveFormsModule,
    MatCardModule,
    MatIconModule,  
    MatButtonModule,  
    MatInputModule,   
    ButtonModule, 
    DividerModule,
    CardModule,
    InputTextModule,
    FloatLabelModule,
    IconFieldModule,
    InputIconModule, 
  ],
  templateUrl: './auth.component.html',
  styleUrl: './auth.component.scss',
  providers: [MessageService]
})
export class AuthComponent implements OnInit {  

  register: boolean = false;  
  private cleanupEffect: EffectRef | undefined; 
  showSpinner = signal(false);

  private unsubscribe$: Subject<void> = new Subject<void>();  
 
  login: FormGroup = new FormGroup({
    password: new FormControl('', [Validators.required, Validators.minLength(8)] ),
    email: new FormControl('', [Validators.required, Validators.email])
  });  
 
  constructor(
    // private  firebaseuiAngularLibraryService: FirebaseuiAngularLibraryService,
    public translationServ : TranslationService,
    private router: Router,  
    public fbAuthService: FirebaseAuthService,
    private messageService: MessageService 
    ) {  
      this.fbAuthService.logout(); // enforcements of logout
    }
 
  ngOnInit() {   this.fbAuthService.logout();   }

  showRegister() {
    const usernameControl = new FormControl('', [Validators.required ]);
    this.login.addControl('username', usernameControl); 
    this.register = true;
  }

  showLogin() {
    this.login.removeControl('username');
    this.register = false;
  }

  registerOnFirebase() {

    if (this.login.invalid)   return; 

    this.showSpinner.set(true);

    const username = this.login.get('username')?.value!;
    const email = this.login.get('email')?.value!;
    const password = this.login.get('password')?.value!;

    this.fbAuthService.register(username, email, password).pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: () => {
        console.log('register success');
        this.router.navigate(['/']);
        this.showSpinner.set(false);
      },
      error: (error) => {
        console.error('register 400 error: bad request', error);
        this.showSpinner.set(false);
      }    
    });
  } 

 
 
  loginOnFirebase() {

    if (this.login.invalid)   return; 

    this.showSpinner.set(true);

    const email = this.login.get('email')?.value!;
    const password = this.login.get('password')?.value!;

    this.fbAuthService.login(email, password).pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: () => {
        console.log('login success');
        this.router.navigate(['/']);
        this.showSpinner.set(false);
      },
      error: (error) => {
        console.error('login 400 error: bad request', error);
        this.showSpinner.set(false);
      }    
    });
  } 

  forgotPassword(email: string) { 
    this.showSpinner.set(true);

    this.fbAuthService.resetEmail(email).pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: () => {  
        const message =  ( this.translationServ.translationsSIG()?.['check-inbox'] ??  'check-inbox' ) + ' !' ;
        this.messageService.add({ severity: 'warn', summary: 'ATTENZIONE', detail: message }); 
        this.showSpinner.set(false);
      },
      error: (error) => {
        console.error('reset email 400 error: bad request', error);
        const message =  ( this.translationServ.translationsSIG()?.['reset-pw-failded'] ??  'reset-pw-failded' ) + ' !' ;
        this.messageService.add({ severity: 'error', summary: 'ATTENZIONE', detail: message }); 
        this.showSpinner.set(false);
      }    
    });
  }


  successCallback(userData: FirebaseUISignInSuccessWithAuthResult) {
     // console.log('successCallback', userData.authResult.user);
    this.router.navigate(['/']);
  }

  errorCallback(userData: FirebaseUISignInFailure) {
    console.warn('errorCallback', userData);
    this.messageService.add({ severity: 'error', summary: 'ATTENZIONE', detail: 'LOGIN FAILED' }); 
  }

  uiShownCallback() {
     // console.log('UI shown');
  }


  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();

    // Pulizia dell'effect quando il componente viene distrutto
    if (this.cleanupEffect) {
      this.cleanupEffect.destroy();
    } 
  }

} 